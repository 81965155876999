import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TimetableState {
  timetable: any[];
  activeItem: any | null;
}

const initialState: TimetableState = {
  timetable: [],
  activeItem: null,
};

const timetableSlice = createSlice({
  name: 'timetable',
  initialState,
  reducers: {
    setTimetable(state, action: PayloadAction<any[]>) {
      state.timetable = action.payload;
    },
    setActiveItem(state, action: PayloadAction<any>) {
      state.activeItem = action.payload;
    },
  },
});

export const { setTimetable, setActiveItem } = timetableSlice.actions;
export const selectTimetable = (state: any) => state.timetable.timetable;
export const selectActiveItem = (state: any) => state.timetable.activeItem;
export default timetableSlice.reducer;
