export const ROUTES = {
  HOME: "/",
  ABOUT: "/about",
  SETUP: "/setup",
  ADJUDICATOR: "/adjudicator",
  CURRENT: "/current",
  MC: "/mc",
} as const;

export default ROUTES;
type t = typeof ROUTES;
export type RoutesType =  t[keyof t];