import React, { Suspense, useState, useEffect } from 'react';
import { Link, BrowserRouter as Router } from 'react-router-dom';
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  styled
} from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { selectDarkMode, changeDarkMode } from './redux/homeSlice';
import { orange, red, deepOrange, blueGrey } from '@mui/material/colors';
import AppRoutes from './app/routes';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import Image from 'mui-image';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import {
  selectSocketConnected,
  selectVisualEffects
} from './redux/settingsSlice';
import { selectJudge } from './redux/workSlice';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import { Avatar } from '@mui/material';
import ROUTES from './constants/routes';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`
      },
      '& + .MuiSwitch-track': {
        opacity: 1
        // backgroundColor: theme.palette.mode !== "dark" ? "#8796A5" : "#aab4be",
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode !== 'dark' ? blueGrey[700] : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff'
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`
    }
  },
  '& .MuiSwitch-track': {
    // opacity: 1,
    // backgroundColor: theme.palette.mode !== "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2
  }
}));
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}
function HideOnScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    threshold: 0.1
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
const App: React.FC = (props) => {
  const [darkState, setDarkState] = useState(false);
  const darkMode = useAppSelector(selectDarkMode);
  const visualEffects = useAppSelector(selectVisualEffects);

  const palletType = darkMode ? 'dark' : 'light';
  const mainPrimaryColor = darkMode ? orange[500] : blueGrey[500];
  const mainSecondaryColor = darkMode ? deepOrange[900] : red[500];

  const visualSettings = visualEffects
    ? {}
    : {
        // Name of the component ⚛️
        MuiButtonBase: {
          defaultProps: {
            // The props to apply
            disableRipple: true // No more ripple, on the whole application 💣!
          }
        },
        MuiCssBaseline: {
          styleOverrides: {
            '*, *::before, *::after': {
              transition: 'none !important',
              animation: 'none !important'
            }
          }
        }
      };

  let darkTheme = createTheme({
    palette: {
      mode: palletType,
      primary: {
        main: mainPrimaryColor
      },
      secondary: {
        main: mainSecondaryColor
      },
      contrastThreshold: 3
    },
    components: visualSettings
  });

  darkTheme = responsiveFontSizes(darkTheme);
  const isSocketConnected = useAppSelector(selectSocketConnected);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (typeof darkState === 'undefined') {
      setDarkState(false);
    }
    setDarkState(darkMode ? true : false);
  }, []);
  useEffect(() => {
    dispatch(changeDarkMode(darkState));
  }, [darkState]);
  const handleThemeChange = () => {
    setDarkState(!darkState);
  };
  const storedJudge = useAppSelector(selectJudge);
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline enableColorScheme />

      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Box sx={{ height: '100vh', display: 'flex' }}>
            <CssBaseline />
            <HideOnScroll {...props}>
              <AppBar component="nav">
                <Container maxWidth="xl">
                  <Toolbar disableGutters variant="dense">
                    <Box sx={{ flexGrow: 1, justifyContent: 'flex-start' }}>
                      <Stack direction={'row'} spacing={1}>
                        <Link to={ROUTES.HOME} replace={true}>
                          <Image
                            src="android-chrome-512x512.png"
                            fit="contain"
                            height="2rem"
                            width="2rem"
                          />
                        </Link>
                        <Box
                          flexGrow={1}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          {storedJudge && storedJudge.uuid !== '' && (
                            <Stack
                              direction={'row'}
                              spacing={1}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <Avatar
                                variant="square"
                                sx={{
                                  width: 30,
                                  height: 30,
                                  bgcolor: darkState ? 'white' : 'black'
                                }}
                              >
                                {storedJudge.letter}
                              </Avatar>
                              <Typography
                                variant="h6"
                                sx={{ textAlign: 'center' }}
                              >
                                {storedJudge.name}
                              </Typography>
                            </Stack>
                          )}
                        </Box>
                      </Stack>
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                      <Stack direction="row" sx={{ alignItems: 'center' }}>
                        {!isSocketConnected && (
                          <Alert severity="warning" variant="filled">
                            <WifiOffIcon />
                            {/* {t('socket.alert.disconnected_short')} */}
                          </Alert>
                        )}
                        <MaterialUISwitch
                          checked={darkState}
                          onChange={handleThemeChange}
                        />
                      </Stack>
                    </Box>
                  </Toolbar>
                </Container>
              </AppBar>
            </HideOnScroll>
            <Box
              sx={{ display: 'flex', flexGrow: 1, pt: '50px', height: '100vh' }}
              component="main"
            >
              <AppRoutes />
            </Box>
          </Box>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
};

export default App;
